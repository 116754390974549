import * as React from "react";
import { FunctionComponent } from "react";
import SubmissionSuccessPage from "../templates/SubmissionSuccess/SubmissionSuccess";

const SurepayAuthorizationSuccess: FunctionComponent = (): JSX.Element => (
  <SubmissionSuccessPage
    heading="SurePay Authorization Submitted"
    content="Thank you for submitting your SurePay Authorization Form. You will receive an email from us within 24 hours to confirm that your enrollment in Hawaiiana’s SurePay service has been received."
  />
);

export default SurepayAuthorizationSuccess;
